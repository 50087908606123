<template>
  <div class="agenda">
    <div class="agendItem">
      <el-dialog
        :title="title"
        :visible="orderVisible"
        width="700px"
        class="videoPlayDialog"
        @close="orderVisibleChange()"
      >
        <el-form label-width="90px">
          <el-form-item prop="date" class="time">
            <template slot="label">
              <div class="labelSlot">预约日期</div>
            </template>
            <el-date-picker
              v-model="agendaForm.day"
              :picker-options="pickerOptions"
              type="date"
              size="small"
              placeholder="预约日期"
              @change="handleDay"
            ></el-date-picker>
          </el-form-item>

          <el-form-item prop="end_time" class="time">
            <template slot="label">
              <div class="labelSlot">预约时间</div>
            </template>
            <el-time-select
                v-model="agendaForm.start_time"
                :picker-options="{
                 start: '00:00',
                 step: '00:10',
                 end: '23:20',
                 minTime: date
                 }"
                size="small"
                format="HH:mm"
                value-format="HH:mm"
                placeholder="起始时间"
                @change="handleStartTime"
            >
            </el-time-select>
            <span>-</span>
            <el-time-select
                size="small"
                placeholder="结束时间"
                v-model="agendaForm.end_time"
                format="HH:mm"
                value-format="HH:mm"
                :picker-options="{
                start: '00:10',
                step: '00:10',
                end: '23:50',
                minTime: agendaForm.start_time ? agendaForm.start_time : date
                }"
            >
            </el-time-select>
          </el-form-item>
          <el-form-item prop="send_postscript" label="备注">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="agendaForm.send_postscript"
              maxlength="200"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div></div>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="orderVisibleChange()" size="small">取消</el-button>
          <el-button
            type="primary"
            size="small"
            @click="confimAgenda"
            :loading="btnLoading"
            >确认</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import { secondFormat } from "~/basePlugins/filters";
  export default {
  props: {
    orderVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "预约",
    },
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      btnLoading: false,
      talkVisible: false,
      dialogTxt: "",
      agendaForm: {
        start_time: "",
        day: "",
        end_time: "",
        send_postscript: "",
      },
      pitchObj: {},
      date: ''
    };
  },
  watch: {
    orderVisible() {
      if (!this.orderVisible) {
        this.btnLoading = false;
        this.agendaForm = {
          start_time: "",
          day: "",
          end_time: "",
          send_postscript: "",
        };
      } else {
        this.date = secondFormat(moment(new Date()).unix(), 'T')
      }
    },
  },
  methods: {
    hide() {},
    orderVisibleChange(val) {
      this.$emit("orderVisibleShow", val);
    },
    confimAgenda() {
      if (!this.agendaForm.day) {
        return false;
      }
      let startTime = null;
      let endTime = null;
      let nowTime = moment(new Date()).unix();
      let time = this.agendaForm.start_time;
      let hour = Number(time.split(":")[0]);
      let min = Number(time.split(":")[1]);
      let s = Number(hour * 3600) + Number(min * 60);
      startTime = (s + moment(this.agendaForm.day).unix()).valueOf();
      if (this.agendaForm.end_time) {
        let time = this.agendaForm.end_time;
        let hour = Number(time.split(":")[0]);
        let min = Number(time.split(":")[1]);
        let s = Number(hour * 3600) + Number(min * 60);
        endTime = (s + moment(this.agendaForm.day).unix()).valueOf();
      }
      let dayTime = moment(this.agendaForm.day).unix();
      let newTimeObj = {
        start_time: startTime ? startTime : "",
        end_time: endTime ? endTime : "",
        day: dayTime ? dayTime : "",
        send_postscript: this.agendaForm.send_postscript,
      };
      if (!newTimeObj.day) {
        this.$message.warning("日期不能为空");
        return;
      } else if (!newTimeObj.start_time) {
        this.$message.warning("开始时间不能为空");
        return;
      } else if (!newTimeObj.end_time) {
        this.$message.warning("结束时间不能为空");
        return;
      } else if (newTimeObj.start_time && newTimeObj.start_time < nowTime) {
        this.$message.warning("开始时间不能低于当前时间");
        return;
      } else if (newTimeObj.end_time - newTimeObj.start_time > 1800) {
        this.$message.warning("时间不能超过30分钟");
        return;
      } else if (
        newTimeObj.start_time &&
        newTimeObj.end_time &&
        newTimeObj.start_time >= newTimeObj.end_time
      ) {
        this.$message.warning("开始时间不能大于结束时间");
        return;
      } else {
        this.btnLoading = true;
        this.$emit("dataChange", newTimeObj);
      }
    },
    loadingChange() {
      this.btnLoading = false;
    },
    handleDay(val) {
      let date = secondFormat(moment(val).unix(), 'LL')
      let current =  secondFormat(moment(new Date()).unix(), 'LL')
      if (date === current) {
        this.date = secondFormat(moment(new Date()).unix(), 'T')
      } else {
        this.date = ''
      }
    },
    handleStartTime(val) {
      let time = val
      let hour = Number(time.split(":")[0]);
      let min = Number(time.split(":")[1]);
      let s = Number(hour * 3600) + Number(min * 60);
      let startTime
      let day = moment().startOf('day').unix()
      startTime = s + day + 1800
      this.agendaForm.end_time = secondFormat(startTime, 'T')
    }
  },
  };
</script>
<style scoped lang="less">
.time {
  .el-date-editor {
    width: 50%;
  }
  /deep/ .el-form-item__content {
    display: flex;
  }
}
.labelSlot::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}

.mt {
  margin-top: 15px;
}
.bt {
  margin-bottom: 10px;
}
.notopen {
  text-indent: 2em !important;
}
/deep/.el-dialog__header {
  line-height: 30px;
}
/deep/.el-dialog__body {
  padding: 20px 30px 0 20px;
}
</style>
